<template>
    <van-tabs v-model:active="active" :before-change="beforeChange" @change="onTabChange" sticky offset-top="10.13333vw">
      <van-tab title="信息" name="msg">
        <van-form @submit="onSubmit">
          <van-cell-group title="客户订单信息" icon="friends-o" inset>
            <van-field
                v-model="id"
                name="id"
                v-show="false"
            ></van-field>
            <van-field
                v-model="this.$data['visitor.id']"
                name="visitor.id"
                v-show="false"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.customOrder.code']"
                name="dispatchOrderCustomOrder.customOrder.code"
                label="客户订单编号"
                placeholder="客户订单编号"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.customOrder.name']"
                name="dispatchOrderCustomOrder.customOrder.name"
                label="客户订单名称"
                placeholder="客户订单名称"
                :readonly="true"

            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.customOrder.customInfo.cnName']"
                name="dispatchOrderCustomOrder.customOrder.customInfo.cnName"
                label="结算客户"
                placeholder="结算客户"
                :readonly="true"

            ></van-field>
            <van-field
                v-model="totalVolume"
                name="totalVolume"
                label="体积"
                placeholder="体积"
                :readonly="true"

            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.addressStart']"
                name="dispatchOrderCustomOrder.addressStart"
                label="起始点"
                placeholder="起始点"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.lnglatStart']"
                name="dispatchOrderCustomOrder.lnglatStart"
                label="起始点经纬度"
                placeholder="起始点经纬度"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.addressEnd']"
                name="dispatchOrderCustomOrder.addressEnd"
                label="目的地"
                placeholder="目的地"
                :readonly="true"
            ></van-field>
            <van-field
                v-model="this.$data['dispatchOrderCustomOrder.lnglatEnd']"
                name="dispatchOrderCustomOrder.lnglatEnd"
                label="目的地经纬度"
                placeholder="目的地经纬度"
                :readonly="true"
            ></van-field>
            <Picker
                name="nodeInfo.id"
                label="选择节点类型"
                placeholder="选择节点类型"
                displayField="name"
                v-model:value="this.$data['nodeInfo.id']"
                :select-first="true"
                :defConfig="{
                     portName: 'tms.basic.nodeInfo.getList',
                     data: {'node.id':this.$data['dispatchVehicle.dispatchOrder.node.id'],
                            'vcustomId':this.$data['id']}
                 }"
                required
                :rules="[{ required: true, message: '请选择选择节点类型' }]"
            ></Picker>

          </van-cell-group>

          <div style="margin: 4.26667vw">
<!--            <van-button round block type="primary" native-type="submit" :disabled="btnDisabled">-->
<!--              选择节点上报-->
<!--            </van-button>-->
          </div>
        </van-form>
      </van-tab>

      <van-tab title="上报记录" name="material" :beforeChange="beforeChange">
        <CommonReserveMaterial
                ref="material"
                :reserveID="this.id"
                :is-load = "this.isLoad"
                :isHazardous="this.isHazardous"
                :back="pageChange"
                :nodeInfoId="this.$data['nodeInfo.id']"
                :nodeId=" this.$data['dispatchVehicle.dispatchOrder.node.id']"
        />
      </van-tab>
    </van-tabs>

</template>
<script>

import Picker from "../../components/Picker";
import DatetimePicker from "../../components/DatetimePicker";
import DatePicker from "../../components/DatePicker";
import _ from "lodash";
import CommonReserveMaterial from "./CommonReserveMaterial";
import {Toast} from "vant";
import {reactive} from "vue";
import "vue3-video-play/dist/style.css";
import {videoPlay} from "vue3-video-play";
import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player'
import globals from '../../public/js/global'

export default {
  name: "CommonVehicleReserve",
  components: { CommonReserveMaterial,Picker},
  data() {
    return {
      active: '',
      showTab: false,
      id: '',
      totalVolume:'',
      "dispatchOrderCustomOrder.customOrder.code":'',
      "dispatchOrderCustomOrder.customOrder.name":'',
      "dispatchOrderCustomOrder.customOrder.customInfo.cnName":'',
      "dispatchOrderCustomOrder.addressStart":'',
      "dispatchOrderCustomOrder.addressEnd":'',
      "dispatchOrderCustomOrder.lnglatEnd":'',
      "dispatchOrderCustomOrder.lnglatStart":'',
      "dispatchVehicle.dispatchOrder.node.id":'',
      "nodeInfo.id":"",
      nameStart:'',
      nameEnd:"",
      dataList:[],
      drivingLicenseFront: [],
      drivingLicenseBack: [],
      operationAttach: [],
      report: [],
      btnDisabled: false,
      showDialog: true,
      videoShow: true,
      purposeReadOnly: false,
      isLoad: true,
      isHazardous: false,
      loadType:'mp4',
      playerConfig:{}, // 播放器配置
      count:0,
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    //this.checkPass();
  },
  beforeUnmount() {
    if (_.isFunction(globals.mp.destory)) {
      globals.mp.destory()
    }
  },
  watch: {
    id: function (val) {
      if (!_.isEmpty(val)) {
        this.showTab = true;
      } else {
        this.showTab = false;
        this.purposeReadOnly = false;
      }
    }
  },
  methods: {
    loadData() {
      var me = this;
      var id = me.$route.params.id;
      if (!_.isEmpty(id)) {
        me.btnDisabled = false;
        me.showDialog = false;
        var option = {
          portName: 'tms.transport.dispatchOrderVehicleCustom.getEntity',
          data: {id: id},
          needLoading: false,
          successCallback: function (data) {
            var entities = data.entities;
            if (!_.isEmpty(entities)) {
              var entity = entities[0];
              var _data = me.$data;
              for (var attrName in entity) {
                if (_data[attrName] != undefined) {
                  _data[attrName] = entity[attrName];
                }
              }
            }
          }
        }
        this.$sapi.callPort(option);
      } else {
        var visitor = this.$sapi.storage.getItemJson('visitor');
        this.$data['visitor.id'] = visitor.id;
        this.name = visitor.name;
        this.tel = visitor.tel;
        this.unit = visitor.unit;
        this['genderDic.id'] = visitor['genderDic.id'];
      }
    },

    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 200, true);
    },
    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
    onCarConfirm(value) {
      this.brand = value.brand;
      this['typeDic.id'] = value['typeDic.id'];

      var drivingLicenseFront1 = value['drivingLicenseFront'];
      var drivingLicenseBack1 = value['drivingLicenseBack'];
      var operationAttach1 = value['operationAttach'];
      if (drivingLicenseFront1.length !== 0) {
        var option = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: drivingLicenseFront1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.drivingLicenseFront = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option);
      }
      if (drivingLicenseBack1.length !== 0) {
        var option1 = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: drivingLicenseBack1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.drivingLicenseBack = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option1);
      }
      if (operationAttach1.length !== 0) {
        var option2 = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: operationAttach1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.operationAttach = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option2);
      }
    },
    onTabChange(index) {
      if (index === "msg") {
        return;
      }
      this.count+=1
      if(this.count>2)
        this.$refs.material.loadData()
    },
    onSubmit(values) {
      this.active= "material";
      this.onTabChange("material");
      // values['dispatchOrder.id'] = this['id'];
      // var me = this;
      // var option = {
      //   portName: 'tms.transport.dispatch.saveEntityVapp',
      //   data: values,
      //   needLoading: false,
      //   successCallback: (data) => {
      //     this.id = data.data.id;
      //     this.active= "material";
      //     this.onTabChange("material");
      //   }
      // };
      // this.btnDisabled = true;
      // try {
      //   this.$sapi.callPort(option);
      // } finally {
      //   this.btnDisabled = false;
      // }
    },
    onSubmitReserve() {
      var option = {
        portName: 'osp.car.VehicleReserve.submitVehicleReserve',
        data: {ids: [this.id]},
        needLoading: false,
        successCallback: (data) => {
          this.$notify({type: 'success', message: '提交成功'});
          this.purposeReadOnly = true;
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    pageChange(){
      this.active= "msg";
    },
    beforeChange() {
      var id = this.id;
      if(this.$data['nodeInfo.id'] === "" && this.active==='msg'){
        Toast("请先选择节点");
        return;
      }
      if(this.active==='msg'){
        this.active= "material";
        this.onTabChange("material");
      }
      var beforeChange = false;
      if (!_.isEmpty(id)) {
        beforeChange = true;
      } else {
        beforeChange =  false;
      }
      return beforeChange;
    }
  }
}
</script>

<style scoped>

</style>